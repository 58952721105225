import DrawerPanel from "antd/es/drawer/DrawerPanel";
import React from "react";

function HomeDashboard({ projects, activeProject, company }) {
  const data =
    projects &&
    projects[activeProject] &&
    projects[activeProject].usage.current;



  function formatNumber(num) {
    if (num >= 1000000) {
      return Math.floor(num / 1000000) + 'M'; // Millions without decimal
    } else if (num >= 1000) {
      return Math.floor(num / 1000) + 'k'; // Thousands without decimal
    }
    return num; 
  }

  return (
    <div className="flex h-screen bg-slate-50">
      {" "}
      {/* Flex container for the entire view */}
      <main className="flex-grow flex flex-col">
        {" "}
        {/* Main content takes the available space, displayed as flex and column */}
        <div className="flex justify-between items-start w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          {" "}
          {/* Flex container for the header, full width, items aligned to the top */}
          {/* Left-aligned "Current Data" */}
          <div className="flex-shrink-0">
            {" "}
            {/* Ensures this part does not grow but can shrink if needed */}
            <span className="text-lg font-medium">Your usage stats</span>
          </div>
        </div>
        <div className="m-10">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Workspace Usage
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  Workspace Call Usage
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${Math.ceil(
                      projects[activeProject].usage.current.callTime / 60
                    )} mins`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  Workspace Chat Usage
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${Math.ceil(
                      projects[activeProject].usage.current.questions
                    )} msgs`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  Inbound
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${data.inboundCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  Outbound
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${data.outboundCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  WebCall
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${data.webCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>
          </dl>
        </div>
        <div className="m-10">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Company Usage
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Overall Call Usage
              </dt>
              {data ? (
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {company && company.projectLevelQuotaCheck === false ? (
                    <>
                      {Math.ceil(company.usage.current.callTime / 60)} min /{" "}
                      {Math.ceil(
                        projects?.[activeProject]?.company?.voiceSecLimit / 60
                      )}{" "}
                      min
                    </>
                  ) : (
                    <>
                      {Math.ceil(data.callTime / 60)} min /{" "}
                      {Math.ceil(projects?.[activeProject]?.voiceSecLimit / 60)}{" "}
                      min
                    </>
                  )}
                </dd>
              ) : (
                <dd className="animate-pulse bg-gray-300 h-6 w-48 rounded"></dd>
              )}
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  Overall Chat Usage
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {data ? (
                    `${formatNumber(
                      company.usage.current.questions
                    )} / ${formatNumber(company.allowedQuestions)} msgs`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>
          </dl>
        </div>
      </main>
      <aside className="w-96 overflow-y-auto border-l border-gray-200 lg:block hidden">
        {" "}
        {/* Aside component with a set width */}
        {/* Content of aside */}
      </aside>
    </div>
  );
}

export default HomeDashboard;
