import React, { useState, useEffect } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import * as voiceAPIServices from "../../services/voiceAPIService";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Fieldset, Label, Legend } from "../../common/fieldset";
import { Divider } from "../../common/divider";
import { message } from "antd";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";

function TwilioComponent({ activeProject, projects }) {
  const [formFieldforTwilio, setFormFieldForTwilio] = useState({
    twilioAccountSID: "",
    twilioAuthToken: "",
    twilioPhoneNumber: "",
  });

  const [checkboxValues, setCheckboxValues] = useState({
    call: false,
    sms: false,
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  const [errorMessage] = useState({});

  // let auth = useAuth();
  //   const history = useHistory();

  const [IsSelectionOpen, setIsSelectionOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await thirdPartyServices.getthirdpartyData(
            projects[activeProject]._id
          );
          const { twilioAccountSID, twilioAuthToken, twilioPhoneNumber } =
            response.data.telephonySettings.twilioSettings;
          const twilioSettings = {
            twilioAccountSID,
            twilioAuthToken,
            twilioPhoneNumber,
          };
          setFormFieldForTwilio(twilioSettings);
        }
      } catch (error) {}
    };
    fetchData();
  }, [projects, activeProject]);

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateTwilio = [
    {
      name: "twilioAccountSID",
      label: "Your Twilio Account SID",
      type: "text",
      style: "",
    },
    {
      name: "twilioAuthToken",
      label: "Your Twilio Auth Token",
      type: "password",
      style: "",
    },
    {
      name: "twilioPhoneNumber",
      label: "Your Twilio Phone number (prefix country code)",
      type: "text",
      style: "",
    },
  ];

  const buttonInfoTwilio = {
    label: "Connect Twilio",
    style: "entire-width",
  };

  const schemaTwilio = {
    twilioAccountSID: Joi.string()
      .max(5000)
      .required()
      .label("Your Twilio Account SID"),
    twilioAuthToken: Joi.string()
      .max(5000)
      .required()
      .label("Your Twilio Auth SID"),
    twilioPhoneNumber: Joi.string()
      .regex(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
      .min(6)
      .required()
      .label("Your Twilio Phone number")
      .error(errors => {
        return errors.map(err => {
          if (err.code === 'string.min') {
            return { message: 'Your Twilio Phone number must be at least 6 characters long.' };
          }
          if (err.code === 'string.pattern.base') {
            return { message: 'Please enter a valid phone number format.' };
          }
          if (err.code === 'any.required') {
            return { message: 'Your Twilio Phone number is required.' };
          }
          return err; 
        });
      }),
};

  const handleTwilioConnection = async (data) => {
    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "twilio",
        twilioSettings: {
          twilioAccountSID: data.twilioAccountSID,
          twilioAuthToken: data.twilioAuthToken,
          twilioPhoneNumber: data.twilioPhoneNumber,
        },
      },
    };

    setPhoneNumber(data.twilioPhoneNumber);

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        telephonySettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        setIsSelectionOpen(true);
        message.success("Twilio connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {

        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  const handleOneClickAuthentication = async () => {
    // Create an array for the 'type' based on selected checkbox values
    const selectedTypes = [];
    if (checkboxValues.call) {
      selectedTypes.push("call");
    }
    if (checkboxValues.sms) {
      selectedTypes.push("sms");
    }
  
    let req = {
      number: phoneNumber,
      type: selectedTypes, // Pass the array of types (call, sms, or both)
    };
  
    message.success(`${selectedTypes.join(", ")} connection request`);
  
    try {
      const response = await voiceAPIServices.registerTwilioNumber(
        projects[activeProject]._id,
        req
      );
      if (response.status === 200) {
        message.success("Twilio connected");
        setLoading(false);
        setIsSelectionOpen(true);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setIsSelectionOpen(false);
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  }


  const handleCheckboxChange = (name) => {
    setCheckboxValues((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div>
      <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Twilio Account
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Bring your own Twilio account to build your custom voice project.
            </p>
          </div>

          <div>
            <Form
              key={JSON.stringify(formFieldforTwilio)}
              errorMessage={errorMessage}
              inputField={formFieldforTwilio}
              template={templateTwilio}
              schema={schemaTwilio}
              buttonInfo={buttonInfoTwilio}
              onSubmit={handleTwilioConnection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
        <a
          className=" mt-5"
          href="https://superdashhq.notion.site/Superdash-Twilio-Account-Integration-Instructions-31010363019447fda1aa7479d4081a3f"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>
            Find the integration{" "}
            <span className="text-blue-400">guide here</span>
          </p>
        </a>
      </div>

      <Alert
        open={IsSelectionOpen}
        onClose={() => setIsSelectionOpen(false)}
        closeOnOutsideClick={false}
        size="sm"
      >
        <AlertTitle>Connect Services</AlertTitle>
        <AlertDescription>
          You can connect with call or sms or both by clicking the option below.
        </AlertDescription>
        <AlertBody className="m-5" style={{ display: "flex", alignItems: "center"  }}>
          
          <div>
            <Fieldset>
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox
                    name="call"
                    value="call"
                    onClick={() => {
                      handleCheckboxChange("call");
                    }}
                  />
                  <Label>Call</Label>
                  <Description>
                   You can place or receive calls using this number.
                  </Description>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox name="sms" value="sms" 
                  onClick={() => {
                    handleCheckboxChange("sms");
                  }}
                />
                  <Label>SMS</Label>
                  <Description>
                  You can send and receive SMS using this number
                  </Description>
                </CheckboxField>
              </CheckboxGroup>
            </Fieldset>
          </div>
        </AlertBody>
        <AlertActions>
                
                  <Button onClick={() =>handleOneClickAuthentication()}>OK</Button>
                </AlertActions>

      </Alert>
    </div>
  );
}

export default TwilioComponent;
