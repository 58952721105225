import React, { useState } from "react";
import { message } from "antd";
import * as projectService from "../../../services/projectService";
import ExtractionFields from "./ExtractionFields";
import { AddNewFieldDialog, NewFields } from "./NewFields";

import ExtractionIcon from "../../../images/extraction.svg";
import TabsWithBadge from "../../../common/tabsWithBadge";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Button } from "../../../common/button";

function useExtractions(projectId) {
  const [extraction, setExtractions] = useState([]);

  const fetchExtractions = async () => {
    try {
      const responseData = await projectService.getExtraction(projectId);

      if (responseData.status === 200) {
        setExtractions(responseData.data);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  return {
    extraction,
    fetchExtractions,
  };
}

const TABS = {
  EXTRACTION_FIELDS: "Extraction Fields",
  NEW_EXTRACTION: "New Extraction",
};

const Tabs = {
  [TABS.EXTRACTION_FIELDS]: (props) => <ExtractionFields {...props} />,
  [TABS.NEW_EXTRACTION]: (props) => <NewFields {...props} />,
};

function ParentExtractionModule({ projects, activeProject }) {
  const [isOpen, setIsOpen] = useState(false);
  
  const [newExtractions, setNewExtractions] = useState([]);
  
  const { extraction, fetchExtractions } = useExtractions(
    projects[activeProject]._id
  );
  
  const [tabState, setTabState] = useState(TABS.EXTRACTION_FIELDS);
  
  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === tabState }];
  }, []);

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <div className="flex gap-x-2">
                  <img src={ExtractionIcon} alt="Extraction icon" width={30} />
                  <p className="font-medium text-xl">Extraction</p>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setTabState(TABS.NEW_EXTRACTION);
                      setIsOpen(true);
                    }}
                  >
                    <PlusIcon /> new extraction
                  </Button>
                </div>
              </div>
              <div>
                <TabsWithBadge
                  tabs={tabs}
                  onTabChange={(e, { tab }) => setTabState(tab.name)}
                />
              </div>
              {Tabs[tabState]({
                projects,
                activeProject,
                extraction,
                fetchExtractions,
                newExtractions,
                setIsOpen,
                setNewExtractions,
                onSubmit: () => setTabState(TABS.EXTRACTION_FIELDS),
              })}
            </div>
          </div>
        </div>
      </main>
      <AddNewFieldDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setNewExtractions={setNewExtractions}
      />
    </div>
  );
}

export default ParentExtractionModule;
