import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";
import { AdjustmentsHorizontalIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Button } from "../../../common/button";
import { Dialog, DialogTitle, DialogBody } from "../../../common/dialog";

function TransferMessage({ projects, activeProject, settings, humanAgents, refetchHumanAgents }) {
  const [open, setOpen] = useState(false);

  return <>
    <Button outline onClick={() => setOpen(true)}><AdjustmentsHorizontalIcon /> Configure</Button>
    <Dialog className="relative top-20" open={open} onClose={() => setOpen(false)} size="2xl">
      <DialogTitle>
        <div class="text-lg flex flex-row justify-between">
          <div>Configure Transfer Message</div>
          <div class="text-lg">
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <TransferMessageForm projects={projects} activeProject={activeProject} settings={settings} humanagentsInfo={humanAgents} refetchHumanAgents={refetchHumanAgents} onCancel={() => setOpen(false)} />
      </DialogBody>
    </Dialog>
  </>
}

function TransferMessageForm({
  projects,
  activeProject,
  settings,
  humanagentsInfo,
  refetchHumanAgents,
  onCancel
}) {
  const [inputField, setInputField] = useState({
    transferMessage: "",
    officeHours: true,
  });

  useEffect(() => {
    setInputField({
      transferMessage: humanagentsInfo ? humanagentsInfo.transferMessage : "",
      officeHours: humanagentsInfo ? humanagentsInfo.officeHours : false,
    });
  }, [humanagentsInfo]);

  const [loading, setLoading] = useState(false);


  const [errorMessage] = useState({});

  const template = [
    {
      name: "officeHours",
      label: "Transfer only during office hours",
      description:
        "When enabled, calls will be made to the agent only during office hours",
      type: "toggle",
    },
    {
      name: "transferMessage",
      label: "Transfer Message",
      description: "What do you want AI to say while doing this request",
      type: "textArea",
      style: { height: "150px" },
    },
  ];

  const buttonInfo = { buttons: "2", primaryLabel: "Save", secondaryLabel: "Cancel" };

  const schema = {
    transferMessage: Joi.string().required().max(250).label("Transfer Message"),
    officeHours: Joi.boolean().label("Office hours"),
  };

  const handleYes = async (data) => {
    try {
      const response = await humanAgentService.updateParentAgent(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        refetchHumanAgents();
        onCancel();
        message.success("Transfer message changed succesfully");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onCancel}
    />

  );
}

export default TransferMessage;
